<template>
  <div style="position: relative;z-index:0;">
    <div :ref="id"></div>
  </div>
</template>

<script>
import WangEditor from "wangeditor";
import { Form } from "ant-design-vue";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    configSetting: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:value"],
  data() {
    return {
      id: "",
      content: "",
      instance: null, // 富文本实例
      formItemContext: null,
    };
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.instance.txt.html(newVal);
      }
    },
    content(newVal) {
      this.$emit("update:value", newVal);
      this.formItemContext.onFieldChange();
    },
  },
  created() {
    this.id = this.randomString(12);
    this.formItemContext = Form.useInjectFormItemContext();
  },
  mounted() {
    this.instance = new WangEditor(this.$refs[this.id]);
    Object.assign(
      this.instance.config,
      {
        // 隐藏功能
        excludeMenus: ["video", "videoSelect"],
	    // 上传图片接口
		uploadImgServer: "/admin/ajaxUpload.do",
		uploadFileName: 'file',
		uploadImgHooks: {
			customInsert: function(inserImg, result) {
				var url = result.data.imgUrl;
				inserImg(url);
			}
		},
		// 上传视频接口
		// uploadVideoServer: "/admin/uploadVideo.do",
		// uploadVideoName: 'file',
		// uploadVideoHooks: {
		// 	customInsert: function (inserVideo, result) {
		// 		var url = result.data.videoUrl;
		// 		inserVideo(url);
		// 	}
		// },
        // change事件
        onchange: (html) => {
          this.content = html;
        },
      },
      this.configSetting
    );

    this.instance.create();
    this.instance.txt.html(this.value);
    if (this.configSetting?.disabled) {
		this.instance.disable();
	}
  },
  beforeUnmount() {
    this.instance.destroy();
  },
  methods: {
    // 生成随机生成id
    randomString(len) {
      len = len || 32;
      let $chars =
        "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      let maxPos = $chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return "editor" + pwd;
    },
  },
};
</script>
